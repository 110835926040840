<template>
  <div class="nav-wrapper">
    <ul class="nav-body">
      <li
        class="nav-item"
        :class="{ active: item.active }"
        :key="item.id"
        v-for="(item, index) in navList"
        @mouseenter="moveHandle(index)"
        @click="
          $router.push(
            {
              path: item.destination,
            },
            () => {}
          )
        "
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
// import { deepClone } from "@/utils/index";
export default {
  methods: {
    moveHandle(index) {
      this.navList.splice(
        0,
        9999,
        ...this.navList.map((it, idx) => {
          it.active = idx === index ? true : false;
          return it;
        })
      );
    },
  },
  data() {
    return {
      navList: [
        { destination: "/home", name: "首页", id: 1, active: true, parmas: {} },
        {
          destination: "/about/1",
          name: "关于我们",
          id: 2,
          active: false,
          params: { page: 1 },
        },
        {
          destination: "/example/1",
          name: "咨询案例",
          id: 3,
          active: false,
          parmas: { page: 1 },
        },
        {
          destination: "/compliance-train/1",
          name: "合规培训",
          id: 7,
          active: false,
          parmas: { page: 1 },
        },
        {
          destination: "/meeting-activity/1",
          name: "会议活动",
          id: 5,
          active: false,
          parmas: { page: 1 },
        },
        {
          destination: "/admissions/1",
          name: "企业合规师考试",
          id: 6,
          active: false,
          parmas: { page: 1 },
        },
        {
          destination: "/expert-point/1",
          name: "专家观点",
          id: 4,
          active: false,
          parmas: { page: 1 },
        },
        {
          destination: "/compliance-observe/1",
          name: "合规观察",
          id: 8,
          active: false,
          parmas: { page: 1 },
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.nav-wrapper {
  width: 100%;
  height: 50px;
  // background: #0086a0;
  background-color: #0957a9;
  color: #fff;

  .nav-body {
    list-style: none;
    width: 1000px;
    height: 50px;
    margin: 0 auto;

    .nav-item {
      float: left;
      padding: 0 29.4px;
      font-size: 16px;
      line-height: 50px;
      cursor: pointer;

      &.active {
        // background: #009dbc;
        background-color: #0b6fda;
      }
    }
  }
}
</style>
