<template>
  <div class="home-layout">
    <the-header></the-header>
    <the-nav></the-nav>
    <router-view></router-view>
    <the-footer></the-footer>
    <!-- <the-copyright></the-copyright> -->
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheNav from "./components/TheNav.vue";
// import TheCarousel from "./components/TheCarousel.vue";
import TheFooter from "./components/TheFooter.vue";
// import TheCopyright from "./components/TheCopyright.vue";

export default {
  name: "HomeView",
  components: {
    TheHeader,
    TheNav,
    TheFooter,
    // TheCarousel,
    // TheCopyright,
  },
};
</script>
