<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import TheHeader from "./views/TheHeader";
export default {
  // components: {
  //   TheHeader,
  // },
  methods: {
    isMobile() {
      if (
        navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        // 当前设备是移动设备
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    // 获取当前屏幕宽度
    // const screenWidth =
    //   window.innerWidth ||
    //   document.documentElement.clientWidth ||
    //   document.body.clientWidth;

    // function updateBodyStyles() {
    //   // 获取body元素
    //   var bodyElement = document.querySelector("body");

    //   // 更新body的width属性
    //   // bodyElement.style.width = screenWidth + "px";

    //   console.log(screenWidth, "screenWidth");

    //   // 如果屏幕宽度大于768px，则添加特定的CSS
    //   // if (screenWidth > 768 * devicePixelRatio) {
    //   if (screenWidth < 1142) {
    //     bodyElement.style.width = screenWidth + 40 + "px";
    //     // bodyElement.style.backgroundColor = "lightblue";
    //     // bodyElement.style.fontFamily = "Arial, sans-serif";
    //     // 添加其他需要的样式
    //   } else {
    //     bodyElement.style.width = "auto";
    //   }
    // }

    // // 初始化页面时调用一次
    // updateBodyStyles();

    // // 在窗口大小发生变化时调用
    // window.addEventListener("resize", updateBodyStyles);

    //动态加载 JS 文件
    function loadJS(files, done) {
      // 获取head标签
      const head = document.getElementsByTagName("head")[0];
      Promise.all(
        files.map((file) => {
          return new Promise((resolve) => {
            // 创建script标签并添加到head
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = file;
            // 监听load事件，如果加载完成则resolve
            s.addEventListener("load", () => resolve(), false);
            head.appendChild(s);
          });
        })
      ).then(done); // 所有均完成，执行用户的回调事件
    }

    let src = "https://v1-reok6.kuaishangkf.com/bs/ks.j?cI=571416&fI=164865";

    loadJS([this.isMobile() ? src + "&ism=1" : src], () => {
      // 用户的回调逻辑
      console.log("快商通JS加载成功!");
      console.log(this.isMobile() ? src + "&ism=1" : src);
    });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 12px;
  min-width: 1142px;
}
a {
  text-decoration: none;
}

/* @media (min-width: 768px) {
  body {
    width: 1130px !important;
  }
} */
</style>
