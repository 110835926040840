<template>
  <div>
    <div class="footer-wrapper">
      <div class="footer-container">
        <dl v-for="item in articleMenu" :key="item.type">
          <dt>
            <a href="javascript:;">{{ item.name }}</a>
          </dt>
          <dd v-for="article in item.article" :key="article.id">
            <a href="javascript:;" @click="toArticle(item, article)">{{
              article.title
            }}</a>
          </dd>
        </dl>
        <div class="qr-code pc">
          <!-- <img src="../../../assets/gongzhonghaotupian.png" alt="" /> -->
          <img :src="config.wechat_that_person_account" alt="" />
          <span>微信二维码</span>
        </div>
        <div class="qr-code pc" style="margin-right: 20px">
          <!-- <img src="../../../assets/gongzhonghaotupian.png" alt="" /> -->
          <img :src="config.wechat_official_account" alt="" />
          <span>微信公众号</span>
        </div>
      </div>
      <div class="qr-code mb">
        <!-- <img src="../../../assets/gongzhonghaotupian.png" alt="" /> -->
        <img :src="config.wechat_official_account" alt="" />
        <span>微信公众号</span>
      </div>
    </div>
    <div class="copyright">
      <p>
        <a href="#"
          >Copyright © 2023 {{ config.web_name }}.All Rights Reserved</a
        >
      </p>
      <p>
        邮箱：{{ config.admin_email }} ｜ 电话：{{ config.admin_phone }} ｜
        地址：{{ config.address }}
      </p>
      <p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"
          >京公网安备 {{ config.web_icp_number }} 号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { configDetail, articleTop } from "@/api/home";
export default {
  data() {
    return {
      config: {},
      articleMenu: [],
    };
  },
  created() {
    configDetail().then((res) => {
      const config = {};
      res.data.forEach((item) => {
        config[item.name] = item.value;
        config[`${item.name}.${item.id}`] = item.id;
      });
      this.config = config;
    });
    articleTop().then((res) => {
      this.articleMenu = res.data.filter((article) => {
        return [4, 5, 6, 7].includes(article.type);
      });
    });
  },
  methods: {
    toArticle(menu, article) {
      // console.log(article.type);
      const pathMap = {
        0: "/home/1",
        5: "/about/1",
        6: "/example/1",
        7: "/expert-point/1",
        4: "/meeting-activity/1",
        8: "/admissions/1",
        1: "/up-to-date/1",
        2: "/activity-preview/1",
      };
      this.$router.push({
        path: pathMap[menu.type],
        query: {
          id: article.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
// 移动端底部垫片
.mobile-shim {
  width: 100%;
  display: none;
  height: 20px;
}
.copyright {
  width: 1000px;
  height: 66px;
  margin: 0 auto;
  padding-top: 24px;

  p {
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    a {
      // color: #00a5c5;
      color: #3d96f5;
    }
  }
}
.footer-wrapper {
  width: 100%;
  min-height: 220px;
  // background: #065868;
  background-color: #0957a9;
  .footer-container {
    // display: flex;
    width: 1000px;
    // height: 154px;
    padding: 33px 0;
    margin: 0 auto;

    dl {
      float: left;
      margin-right: 56px;
      color: #fff;
      font-style: none;
      max-width: 124px;

      dt {
        a {
          display: block;
          width: 124px;
          font-size: 15px;
          display: inline-block;
          line-height: 50px;
          color: #fff;
        }
      }
      dd {
        max-width: 124px;
        a {
          display: block;
          width: 124px;
          font-size: 12px;
          display: inline-block;
          line-height: 20px;
          color: #fff;
          text-decoration: none;
          text-decoration: none;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .qr-code {
    width: 100px;
    float: right;
    margin-top: 16px;

    img {
      width: 100px;
      height: 100px;
      border: 0px;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      width: 100px;
      line-height: 32px;
      color: #fff;
      text-align: center;
    }
  }
}

.qr-code.mb {
  display: none;
}
.qr-code.pc {
  display: block;
}

@media screen and (max-width: 767px) {
  /* 在此处添加你的样式规则 */
  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .mobile-shim {
    display: block;
  }
  .qr-code.mb {
    display: block;
  }
  .qr-code.pc {
    display: none;
  }
}
</style>
