import request from "@/utils/request";

export function carouselIndex(query) {
  return request({
    url: "/page/carousel/index",
    method: "POST",
    params: query,
  });
}
export function articleIndex(query) {
  return request({
    url: "/page/article/index",
    method: "POST",
    params: query,
  });
}
export function configDetail(query) {
  return request({
    url: "/page/configs/detail",
    method: "POST",
    params: query,
  });
}
export function articleTop(query) {
  return request({
    url: "/page/article/top",
    method: "POST",
    params: query,
  });
}
