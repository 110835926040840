<template>
  <div class="header-wrapper">
    <div class="header-logo">
      <router-link to="/">
        <!-- <img src="../../../assets/logo.png" alt="" /> -->
        <img
          src="https://cdn.bowangtech.com/images/20231007/65216d528426d.jpg"
          alt=""
        />
      </router-link>
    </div>
    <div class="header-right" @click="$router.push('/home')">
      <!-- src="	http://www.tncchina.org.cn/Public/static/div1/images/home_06.png" -->
      <img
        src="https://cdn.bowangtech.com/images/20231008/6522ccca476d7.png"
        alt=""
      />官网
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.header-wrapper {
  width: 1000px;
  // height: 120px;
  height: 85px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-logo {
    // width: 432px;
    // height: 60px;
    float: left;
    padding: 30px 0;

    img {
      // height: 60px;
      height: 76px;
      border: 0px;
      vertical-align: middle;
    }
  }
  .header-right {
    cursor: pointer;
    font-size: 14px;
    img {
      vertical-align: top;
      margin-right: 4px;
      width: 20px;
    }
  }
}

/* 对于小屏幕设备（例如手机）的基本样式 */
@media screen and (max-width: 767px) {
  /* 在此处添加你的样式规则 */
  .header-wrapper {
    width: 100%;
    // justify-content: center;
  }
  .header-right {
    display: none;
  }
}
</style>
