import Vue from "vue";
import VueRouter from "vue-router";
import HomeLayout from "@/views/home/layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
    component: HomeLayout,
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/pages/HomePage"),
        meta: {
          name: "首页",
          nameEn: "HOME",
          articleType: 0,
          siteId: 1,
        },
      },
      {
        path: "/about/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "关于我们",
          nameEn: "ABOUT US",
          articleType: 5,
          siteId: 2,
        },
      },
      {
        path: "/example/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "案例咨询",
          nameEn: "CASES",
          articleType: 6,
          siteId: 3,
        },
      },
      {
        path: "/expert-point/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "专家观点",
          nameEn: "INSIGHT",
          articleType: 7,
          siteId: 4,
        },
      },
      {
        path: "/meeting-activity/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "会议活动",
          nameEn: "MEETING&ACTIVITIES",
          articleType: 4,
          siteId: 5,
        },
      },
      {
        path: "/admissions/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "企业合规师考试",
          nameEn: "EXAMINATION",
          articleType: 8,
          siteId: 6,
        },
      },
      {
        path: "/up-to-date/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "最新动态",
          nameEn: "UP TO DATE",
          articleType: 1,
          // siteId: 1, // TODO
        },
      },
      {
        path: "/activity-preview/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "活动预告",
          nameEn: "ACTIVITY PREVIEW",
          articleType: 3,
          // siteId: 1, // TODO
        },
      },
      {
        path: "/expert-intro/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "首席专家介绍",
          nameEn: "EXPERT INTRO",
          articleType: 2,
          // siteId: 1, // TODO
        },
      },
      {
        path: "/compliance-train/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "合规培训",
          nameEn: "COMPLIANCE TRAINING",
          articleType: 9,
          siteId: 7,
        },
      },
      {
        path: "/compliance-observe/:page",
        component: () => import("@/views/home/pages/ListPage"),
        meta: {
          name: "合规观察",
          nameEn: "COMPLIANCE OBSERVATION",
          articleType: 10,
          siteId: 8,
        },
      },
    ],
  },
  // 当无法匹配到任何路由的时候重定向至首页
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "北京丹华盛管理咨询有限公司";
  next();
});

export default router;
